<template>
  <div id="app" class="principal">
    <div class="shadow-lg">
      <b-navbar toggleable="lg" type="light" variant="light" v-if="currentUser">
        <b-navbar-brand href="#">
          <img
            src="./images/logo_mendels_brain.png"
            class="d-inline-block align-top"
            alt="Kitten"
            width="300"
          />
        </b-navbar-brand>
        <b-navbar-nav class="ml-5">
          <b-navbar-nav>
        <!--    <router-link to="/perfil" class="nav-link"
              ><i class="far fa-user fa-2x"></i
            ></router-link>-->
     
            <router-link to="/annotation" class="nav-link ml-5"
              >Annotations</router-link
            >
          </b-navbar-nav>
        </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <li class="nav-item">
              <a class="nav-link" href @click.prevent="logOut">
                <font-awesome-icon icon="sign-out-alt" />Salir
              </a>
            </li>
          </b-navbar-nav>
      </b-navbar>
    </div>
    <div id="block_container" class="d-flex justify-content-between container">
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 2000px;
  position: relative;
}
.container_login {
  max-width: 600px;
  position: relative;
}
.principal {
  background: rgb(140, 175, 54);
  background: radial-gradient(
    circle,
    rgba(140, 175, 54, 1) 0%,
    rgba(134, 173, 53, 1) 42%,
    rgba(77, 159, 44, 1) 76%,
    rgba(57, 154, 41, 1) 90%
  );

  min-height: 100vh;
  min-width: 100vh;
}

.image-logo {
  text-align: center;
}

.texto-logo {
  width: 350px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
.texto-pie {
  text-align: center;
  margin-left: auto;
  margin-top: 100px;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}
.block_container {
  display: inline-block;
  justify-content: center;
}

</style>
